<template>
<div class="light-bg sticky-top myH">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <nav class="navbar navbar-expand-lg navbar-light sb">
                        <router-link class="logoText" to="/">
                            <img height="50" src="@/assets/images/logo_white.png"/>
                            <span style="font-size:20px;color:#000;" class="titlename">CAPTAIN OPPA | 船長哥哥</span>
                        </router-link>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <!-- <span class="icon-menu"></span> -->
                            <div class="menuLine"></div>
                            <div class="menuLine"></div>
                            <div class="menuLine"></div>
                        </button>
                        <div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link" @click="page('monthly')">{{$t('monthly')}}</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" @click="page('quickBook')">{{$t('Qa')}}</a>
                                </li>

                                <li class="nav-item dropdown">
                                    <a class="nav-link al" href="#" id="navbarDropdownMenuLink0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{$t('ss')}}
                                        <span class="selectIcon"></span>
                                        <!-- <font-awesome-icon :icon="['fas', 'caret-down']" /> -->
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink0">
                                        <!--use v-for to loop the api result until the end and use v-bind:href to bind link!-->
                                        <a v-for="(value,i) in category" 
                                            :key="i" class="dropdown-item cur" 
                                            style="color: #000 !important"
                                            @click="changeCate(value)"
                                            >{{$i18n.locale=='zh-TW' ? value.name : value.name_en}}</a>                                     
                                    </div>
                                </li>
                                <!--<li class="nav-item active">
                                    <a class="nav-link" href="http://www.vikingscharters.com/">{{$t('Charters')}}</a>
                                </li>!-->


                                <li class="nav-item">
                                    <router-link class="nav-link" to="/howToBooking">{{$t('howQ')}}</router-link>
                                </li>
                                <li class="nav-item active">
                                    <router-link class="nav-link" to="/contactUS">{{$t('contact')}}</router-link>
                                </li>
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/aboutus">{{$t('aboutUs')}}</router-link>
                                </li>
                                
                                <!-- <li class="nav-item">
                                    <a class="nav-link" href="/#/fulltimejob">找工作</a>
                                </li> -->
                                <li class="nav-item dropdown">
                                    <a class="nav-link al" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {{$t('language')}}
                                        <span class="selectIcon"></span>
                                    </a>
                                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                        <a class="dropdown-item cur" style="color: #000 !important" @click="changeLan('zh-TW')">{{$t('fanti')}}</a>
                                        <!--<a class="dropdown-item cur" style="color: #000 !important" @click="changeLan('zh-CN')">{{$t('jianti')}}</a>!-->
                                        <a class="dropdown-item cur" style="color: #000 !important" @click="changeLan('en-US')">{{$t('English')}}</a>
                                    </div>
                                </li>
                                <li>
                                    <div id="membershipSection">
                                        <router-link v-if="!login" to="/register" class="btn btn-primary top-btn"><span class="ti-plus"></span> {{$t('regLog')}}</router-link>

                                        <div v-else id="user_id" :data-target="user_id" class="dropdown">
                                            <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                {{$t('hello')}}！ {{username}}
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <router-link style="color:black" class="dropdown-item" :to="'/userpanel?id=' + user_id">{{$t('userPanel')}}</router-link>
                                                <a style="color:black" id="logout" class="dropdown-item" @click="logout">{{$t('logout')}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data () {
            return {
                msg2: '',
                category : [],
            }
        },
        computed:{
            login () { return this.$store.state.login },
            username () { return this.$store.state.username },
            user_id () { return this.$store.state.user_id },
        },
        mounted () {
            this.$http.get('category').then((res) => {
                console.log(res)
                if (res.data.code == 200) {
                    this.category = res.data.data
                } else {
                    this.category = []
                }
            })
            .catch(error => console.log(error))
        },
        methods:{
            page (name) {
                if (name == "quickBook" || name == "monthly") {
                    if (!this.login) {
                        this.$router.push('/register')
                    } else {
                        this.$router.push({name})
                    }
                } else {
                    this.$router.push({name})
                }
            },
            changeLan (lan) {
                this.$i18n.locale = lan
                localStorage.setItem("lang",lan)
                
            },
            changeCate (item) {
                console.log(item,this.$route)
                if (this.$route.name != "listing") {
                    this.$router.push("/listing?id=" + item.id)
                } else {
                    this.$store.commit("setState",{ key:"cate_id", value: item.id })
                    
                }
                this.$store.commit("setState",{ key:"cate_name", value: item.name })
            },  
            logout () {
                this.$store.commit("logout")
            }
        }
    }

    // $(window).scroll(function() {
    //     // 100 = The point you would like to fade the nav in.
    //     if ($(window).scrollTop() > 100) {
    //         $('.fixed').addClass('is-sticky');
    //     } else {
    //         $('.fixed').removeClass('is-sticky');
    //     };
    // });
        
</script>

<style scoped>
    .logoText {
        width: 380px;
        color: #000;
        text-decoration: none !important;
    }
    .selectIcon {
        border: solid transparent 5px;
        border-top: solid #000 5px !important;
        transform: translateY(2px);
    }
</style>