export default {
  home: '首页',
  login: '登入',
  components: '组件',
  count_to_page: '数字渐变',
  tables_page: '多功能表格',
  split_pane_page: '分割窗口',
  markdown_page: 'Markdown编辑器',
  editor_page: '富文本编辑器',
  icons_page: '自定义图标',
  img_cropper_page: '图片编辑器',
  update: '上传数据',
  join_page: 'QQ群',
  doc: '文档',
  update_table_page: '上传CSV文件',
  update_paste_page: '粘贴表格数据',
  multilevel: '多级菜单',
  directive_page: '指令',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': '上传excel',
  'export-excel': '导出excel',
  tools_methods_page: '工具函数',
  drag_list_page: '拖拽列表',
  language:"语言",
  Qa:"快速预约船长/水手",
  jianti: "简体中文",
  fanti: "繁體中文",
  English:"English",
  ss:"选择服务",
  howQ:"如何预订",
  contact:"联系我们",
  aboutUs:"关于我们",
  Charters:"游艇租赁",

  hello:"你好",
  regLog:"注册/登录",
  userPanel:"用户界面",
  logout:"登出",
  giveYouService:"船长哥哥提供您需要的服务",
  active:"精选活动",
  longCaptain:"长期船长",
  recommend:"为您推荐",
  noData:"暂无数据",
  hire:"聘用",
  vDetail:"查看",
  sbp:"简单的预约流程",
  step:"步骤",
  sss:"选择所需的服务",

  sort:"排序",
  age:"年龄",
  LH:"低-高",
  HL:"高-低",
  price:"价格",
  search:"搜索",
  enterInfo:"请输入相关资料",
  lookFor:"找寻",
  license:"执照",
  CanRent:"可租用",
  workE:"工作经验",
  HireTheSailor:"聘请这个水手",

  bookingDate:"预定日期",
  bookingTime:"预定时间",
  shippingName:"船只编号",
  shippingLength:"船只长度",
  shippingLocation:"上船地点",
  phone:"联系电话",
  remark:"特殊要求",
  orderDetail:"订单详情资讯",
  needlunch:"需要提供午餐",
  SailingTime:"起航时间",
  iAgree:"我同意以上条款",
  ordering:"正在下单...",
  placeAnOrder:"下单",
  orderNum:"订单编号",
  backIndex:"返回首页",
  myOrder:"我的订单",
  okOrder:"谢谢, 你的订单已经成功创建",
  TheEngineNumber:"主机数量",

  bookingNum:"预定编号",
  serviceNme:"雇佣船长/水手",
  orderStatus:"订单状态",
  action:"操作",
  loading:"加载中...",
  edit:"编辑",
  cancelOrder:"取消订单",
  confirm:"确定",
    unLock:"解锁 船长哥哥 的最佳内容",
    saveStaff:"储存你喜欢的水手或船长并在系统上查看",
    quickBook:"一键快速预约水手或船长!",
    answerQ:"提出问题,交由其他船长哥哥专业顾问为你解答",
    becomeMember:"立即成为会员",
    senior:"CAPTAIN OPPA 的特選駕駛訓練班",
    includeAll:"包括临时船长/水手/长期雇员",
    includeTrain:"包括游艇驾驶培训",
    bookAndSelect:"预约并选择船长/水手/服务",
    hasNews:"有你需要的所有资讯",
    SelectMatch:"根据个人需求选择匹配的船长/水手/服务",
    orderPay:"下订单和付款",
    justSI:"只需要给我们简单的信息",
    youCan5:"您可以在5分钟内完成该过程",
    haveAGoodTrip:"准时到达并使用服务,祝你旅途愉快!",
    chartShip:"租船无难度",
    weijinInfo:"维京游艇有限公司成立于1989年,主要从事游艇租赁、管理及维修等服务，是本港其中一间自资拥有船队的公司",
    lookNow:"立即游览游艇",
    quictB:"快速预约服务",
    quictBMsg:"快速预约服务可以帮助你更快完成预约,只需填写以下内容我们便会有专人为你匹配适合你的船长或水手!",
    bookNow:"立即预约",
    captainNum:"船长数量",
    sailorNum:"水手数量",
    total:"总价格",
    ifOverTime:'*实际出船时间如果超过指定时数,',
    payMore:"顾客请直接与船长/水手自行拟定及交收额外收费",
    thankFor:"多谢阁下选用我们Captain Oppa的船长服务,请打 ????? 联络我们",
    yourTime:"你所要求提供服务的时间",
    cardmsg:"我们Captain Oppa 可以提供合适船长后请将订金入落我們公司汇丰户口 ?????????? 然后我们将入数纸 whatsapp 给我们 ????? ",
    beforeMsg:"出船前一天,我们安排好的船长会联络阁下,确保出船当天准时在相约的码头上船",
    ifQueation:"如有任何问题或不合意的地方,请联系我们Captain Oppa ??????, 谢谢!",

    address:"地址",
    enterName:"输入你的名称",
    enterEmail:"输入你的电邮地址",
    enterTitle:"输入标题",
    enterInfo2:"输入你的讯息",
    sendMsg:"发送讯息",
    resultNum:"个结果",
    pTeam:"专业团队",
    TeamMsg:'我们的团队由航海专家组成，他们将在这里知道你进行游艇租赁研究。我们根据你的需求，你的船员和预算调整报价。如果你对游轮有任何疑问，请联系我们！',
	
	
}
