

<template>
    <div id="app">
        <my-header style="z-index:1000;"></my-header>
        <router-view></router-view>
        <my-footer></my-footer>
    </div>
</template>

<script>
import header from '@/components/header.vue';
import footer from '@/components/footer.vue';
export default {
    name: 'App',
    components:{
         'my-header':header,
         'my-footer':footer
    },
    created () {
        this.$http.get("user").then(res => {
            if (res.data.code == 200) {
                this.$store.commit("setState",{ key: "login",value: true })
                this.$store.commit("setState",{ key: "user_id",value: res.data.data.id })
                this.$store.commit("setState",{ key: "username",value: res.data.data.username })
            } else {
                this.$store.commit("logout")
            }
        }).catch(e => {
            console.log(e)
            this.$store.commit("logout")
        })
    }
}
</script>

<style lang="less">
/* Gobal css */
    @import './assets/css/bootstrap.min.css';
    @import './assets/css/simple-line-icons.css';
    @import './assets/css/style.css';
    @import './assets/css/set1.css';
    @import './assets/css/simple-line-icons.css';
    
    @media screen and (max-width:500px) {
        .orderFormItem {
            padding-left: 5% !important;
            >input {
                width: 90% !important;
            }
        }
        .quickForm {
            flex-direction: column !important;
            >div {
                width: 100% !important;
            }
        }
        .wid100 {
            width: 100% !important;
            transform: translate(0,0) !important;
        }
        .simg {
            height: 60vw !important;
        }
        .centerM {
            width: 90% !important;
        }
        .textBox {
            width: 100% !important;
        }
        .mobileHide {
            display: none !important;
        }
        .booking-checkbox_wrap {
            padding: 0 !important;
        }
        .booking-checkbox_wrap {
            padding: 0.8em !important;
        }
        .formInp {
            width:90% !important;
            border: solid #333 1px;
        }
        .pcTable {
            display: none !important;
        }
        .listWrap {
            display: block !important;
        }
        .gridBox {
            display: block !important;
            width: 100% !important;
        }
        .mPdding {
            padding: 0 4vw;
        }
        .newsImg {
            height: 25vw !important;
        }
        .titlename {
            font-size:16px !important;
        }
        .logoText {
            width: 80% !important;
        }
        .slider {
            background-size: auto 100%;
            background-position: left !important;
        }
        .swiperWrap {
            display: block !important;
        }
        .swiper {
            width: 100% !important;
            height: 30vw;
            margin:0 auto 2vw;
        }
        .manImg {
            width: 26.8vw !important;
            height: 26.8vw !important;
        }
        .myH {
            border-bottom: solid #CCC 1px;
        }
        .detail-filter-text {
            margin-bottom: 1rem;
        }
        .toOrderBtn {
            display: block !important;
        }
        .border-left {
            display: none !important;
        }
        .iju {
            display: flex !important;
            justify-content: space-between !important;
            flex-wrap:nowrap !important;
            align-items:center !important;
        }
        .reserve-rating {
            font-size:0.5rem !important;
        }
        .wid50 {
             width:60% !important;
        }
        .swiper {
            display: none !important;
        }
        .swiper2 {
            display: block !important;
            width: 100%;
            img {
                width: 100%;
                height: 90vw;
            }
        }
        #headerimage {
            background-size: cover !important;
        }
        .line {
            margin: 15px 1.5% !important;
        }
        .width50 {
            width: 100% !important;;
        }
        .width30 {
            width: 100% !important;
        }
        .width600 {
            width: 100% !important;
        }
        .leftTopLogo {
            height: 200% !important;    
        }
        .twoDot {
            width: 0!important;
            height: 0 !important;
            overflow:hidden;
        }
        .borderT {
            width: 80% !important;
        }
        .phoneWrap {
            width: 90% !important;
        }
    }

    #app {
        /*padding:0 10px 10px 10px;*/
    }
    
    
    .ju {
        display: flex !important;
        justify-content: center !important;
    }
    .al {
        display: flex !important;
        align-items: center !important;
    }
    .sb {
        display: flex !important;
        justify-content: space-between !important;
    }
    .op:active {
        opacity: 0.6 !important;
    }
    ul {
        padding: 0;
        margin: 0;
    }
    .flex{ 
        display: flex;
    }
    li {
        list-style: none;
    }
    .relation {
        position: relative;
    }
    .dataLoad {
        width: 3vw;
        height: 3vw;
        border: solid #102452 3px;
        border-radius: 50%;
        border-left: transparent solid 3px !important;
        animation: rotate 1s linear infinite;
        margin: 2vw auto;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 5vw;
        z-index: 100;
    }
    .relative {
        position: relative;
    }
    .cur {
        cursor: pointer;
    }
    .btnLoad {
        width: 15px;
        height: 15px;
        border: solid #FFF 2px;
        border-radius: 50%;
        position: absolute;
        left: 60%;
        top:35%;
        border-left: transparent solid 2px !important;
        animation: rotate 1s linear infinite;
    }
    @keyframes rotate {
        from{
            transform: rotate(0deg);
        }
        to{
            transform: rotate(360deg);
        }
    }
    .clearBoth::after {
        display: block;
        content:"";
        clear:both;
    }
    div {
        scrollbar-color: #606060 #EFEFEF; /* 滑块颜色  滚动条背景颜色 */
        scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */
    }
    div::-webkit-scrollbar {
        background: #EFEFEF;
        width: 10px !important;
    }
    div::-webkit-scrollbar-thumb {
        background: #606060;
    
        border-radius:10px
    }
    div::-webkit-scrollbar-track {
        background: #EFEFEF;
    }
    .twoNum {
        p {
            width: 50%;
            color: #000;
            font-size: 13px;
        }
    }
    .phoneWrap {
        width: 70%;
    }
    .statusTag {
        width: 80%;
        display:inline-block;
        padding: 3px 0;
        text-align: center;
        color: #000;
    }
    .yellowBg {
        background: #F7DE1F;
    }
    .greenBg {
        background: rgb(78, 230, 78);
    }
    .redBg {
        background: #E50000;
    }
</style>


