import Vue from 'vue'
import Router from 'vue-router'
import axios from "axios"

Vue.use(Router)

const router = new Router({
  routes: [
    {
      path:"/",
      name:"index",
      component: () => import ("@/view/index.vue")
    },
    {
        path:"/register",
        name: "register",
        component: () => import ("@/view/register.vue")
    },
    {
        path:"/listing",
        name: "listing",
        component: () => import ("@/view/listing.vue")
    },
    {
        path:"/userPanel",
        name: "userPanel",
        meta: {
            login: true
        },
        component: () => import ("@/view/userPanel.vue")
    },
    {
        path:"/aboutUs",
        name: "aboutUs",
        component: () => import ("@/view/aboutUs.vue")
    },
    {
        path:"/order",
        name: "order",
        component: () => import ("@/view/order.vue")
    },
    {
        path: "/howToBooking",
        name: "howToBooking",
        component: () => import ("@/view/howToBooking.vue")
    },
    {
        path: "/contactUs",
        name: "contactUs",
        component: () => import ("@/view/contactUs.vue")
    },
    {
        path: "/quickBook",
        name: "quickBook",
        component: () => import ("@/view/quickBook.vue")
    },
    {
        path: "/monthly",
        name: "monthly",
        component: () => import ("@/view/monthly.vue")
    }


  ]
})

router.beforeEach ((to,from,next) => {
    document.documentElement.scrollTop = 0

    if (to.meta.login) {
        axios({
            url: "user",
            headers: {
                Authorization: "JWT " + localStorage.getItem("JWT")
            }
        }).then(res => {
            if (res.data.code == 200) {
                next()
            } else {
                next("/register")
            }
        }).catch(() => {
            next("/register")
        })
    } else {
        next()
    }
})

export default router