import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        login: false,
        username: "",
        user_id: "",

        cate_id: "",
        cate_name: ""
    },
    mutations: {    
        setState (state,data) {
            state[data.key] = data.value
        },
        logout (state) {
            state.login = false
            state.username = ""
            state.user_id = ""
        }
    }
})

export default store