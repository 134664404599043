export default {
  home: '首頁',
  login: '登入',
  components: '组件',
  count_to_page: '数字渐变',
  tables_page: '多功能表格',
  split_pane_page: '分割窗口',
  markdown_page: 'Markdown編輯器',
  editor_page: '富文本編輯器',
  icons_page: '自定義圖標',
  img_cropper_page: '圖片編輯器',
  update: '上傳數據',
  join_page: 'QQ群',
  doc: '文檔',
  update_table_page: '上傳CSV文件',
  update_paste_page: '粘貼表格數據',
  multilevel: '多级菜单',
  directive_page: '指令',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': '上傳excel',
  'export-excel': '導出excel',
  tools_methods_page: '工具函數',
  drag_list_page: '拖拽列表',
  language:"語言",
  Qa:"快速預約船長/水手",
  jianti: "简体中文",
  fanti: "繁體中文",
  English:"English",
  ss:"選擇服務",
  howQ:"如何預訂",
  contact:"聯繫我們",
  aboutUs:"關於我們",
  Charters:"遊艇租賃",
  quickBookSentences:"一鍵快速預約船長或水手!",
  hello:"你好",
  regLog:"註冊/登錄",
  userPanel:"用戶界面",
  logout:"登出",
  giveYouService:"船長哥哥提供您需要的服務",
  active:"教練出船",
  longCaptain:"長期船長",
  recommend:"為您推薦",
  noData:"暫無數據",
  hire:"預約",
  vDetail:"查看細節",
  sbp:"簡單的預約流程",
  step:"步驟",
  sss:"選擇所需服務",

  sort:"排序",
  age:"年齡",
  LH:"低-高",
  HL:"高-低",
  price:"價格",
  search:"搜索",
  enterInfo:"請輸入相關資料",
  lookFor:"找尋",
  license:"執照",
  CanRent:"可使用",
  workE:"工作經驗",
  HireTheSailor:"預約",
  engineNumber:"發動機數量(例如 :2個) Number of engine(s) (e.g. 2)",
  bookingDate:"預定日期",
  bookingTime:"預定時間",
  shippingName:"船隻編號",
  shippingLength:"船隻長度",
  shippingLocation:"上船地點",
  phone:"聯繫電話",
  remark:"其他要求",
  specialRequirment:"特殊要求...",
  orderDetail:"訂單詳情資訊",
  needlunch:"可選擇性提供餐點",
  SailingTime:"起航時間",
  iAgree:"我同意以上條款",
  ordering:"正在下單...",
  placeAnOrder:"下單",
  orderNum:"訂單編號",
  backIndex:"返回首頁",
  myOrder:"我的訂單",
  okOrder:"謝謝, 你的訂單已經成功創建",
  TheEngineNumber:"發動機數量 (例如 :2個)  ",
  bookingAddress:"上船地點",
  bookingNum:"預定編號",
  serviceNme:"租用船長/水手",
  orderStatus:"訂單狀態",
  action:"操作",
  loading:"加載中...",
  edit:"編輯",
  cancelOrder:"取消訂單",
  confirm:"確定",
  unLock:"解鎖 船長哥哥 的最佳內容",
  saveStaff:"儲存你喜歡的船長或水手並在系統上查看",
  quickBook:"快速預約船長/水手",
  answerQ:"提出問題，由船長哥哥的專業顧問為你解答",
  becomeMember:"立即成為會員",
  senior:"Captain Oppa 的特選駕駛訓練班",
  includeAll:"包括短期租用船長或水手/ 長期租用船長或水手",
  includeTrain:"包括遊艇駕駛培訓",
  bookAndSelect:"選擇船長/水手/服務",
  hasNews:"有你需要的所有資訊",
  SelectMatch:"根據客戶需求選擇匹配的船長/水手/服務",
  orderPay:"付款",
  justSI:"只需要給我們簡單的信息",
  youCan5:"您可以在5分鐘內完成預約",
  haveAGoodTrip:"準時到達並使用服務，祝你旅途愉快!",
  chartShip:"租船無難度",
  weijinInfo:"維京遊艇有限公司成立於1989年，主要從事遊艇租賃、管理及維修等服務，是本港其中一間自資擁有船隊的公司",
  lookNow:"立即瀏覽遊艇租賃",
  quictB:"快速預約服務",
  quictBMsg:"快速預約服務可以幫助你更快完成預約,只需填寫以下內容我們便會有專人為你匹配最適合你的船長或水手!",
  bookNow:"立即預約",
  captainNum:"船長數量",
    sailorNum:"水手數量",
    total:"總價格",
    ifOverTime:'*實際出船時間，由預定時間起計最多8小時，如果超過指定時數，',
    payMore:"顧客請直接與船長/水手自行擬定及交收額外收費",
    thankFor:"多謝選用我們 Captain Oppa 的服務",
    yourTime:"下單",
    cardmsg:"我們Captain Oppa 可以提供合適船長，預約成功後請將訂金入落我們公司匯豐戶口 CAPTAIN OPPA LIMITED - 143141562001 / FPS - 161224431  ，然後我們將入數紙 whatsapp 給我們 60994051",
    beforeMsg:"出船前一天,我們的船長會聯絡您,確保出船當天準時在相約的地點上船",
    ifQueation:"如有任何問題或不合意的地方,請聯繫我們(60994051), 謝謝!",
    address:"地址",
    enterName:"輸入你的名稱",
    enterEmail:"輸入你的電郵地址",
    enterTitle:"輸入標題",
    enterInfo2:"輸入你的訊息",
    sendMsg:"發送訊息",
    resultNum:"結果",
    pTeam:"我們的團隊",
    TeamMsg:"我們的經驗團隊由經驗豐富的船長和水手組成， 我們在這裏向您提供專業船長 / 水手服務，各類水上活動和遊艇租賃。我們提供的優質服務價格 (公平/具有競爭力)，可滿足您不同需求和預算。",
    TeamMsg2:"如果您對我們有任何疑問或查詢請即聯繫我們 60994051。",
    termtitle:"平台使用條款",
    term1Title:"良好溝通",
    term1:"僱用服務者應與船長 / 水手作良好溝通，以符合本身的服務要求。",
    term1two:"本平台所提供的船長均符合香港海事法例要求，而個別船長操控技術或船隻種類有不同，僱用服務者應與船長/水手之間在開始工作前作良好溝通 。",
    term1three:"本平台只在提供中介船長 / 水手服務，若當中雙方有任何紛爭或不滿，船長 / 水手與僱用服務者應該自行商討解決辦法，本平台絕不會負上任何責任。",
    term1four:"顧用服務者明白船隻停泊位置不同，船長 / 水手在交通上可能需要一些時間配合，雙方需要尋求一個合理解決辦法。",
    term1five:"",
    term2Title:"安全駕駛",
    term2:"僱用服務者必須聽從船長 / 水手建議及安排，以保障船上客人及財物安全。",
    term2two:"僱用教練服務者需要與教練作良好溝通及絕對服從教練指引，若因為不聽從指示而損毀本公提供的船隻或財物則必須照價賠償。",
    term2three:"僱用教練服務者提供自己船隻，必須保證該船隻符合海事處海事法例要求並在適航狀態，否則教練有權拒絕提供服務，而僱用教練服務者所支付之費用不獲退還。",
    term2four:"",
    term3Title:"保險及牌照",
    term3:"僱用服務者必須為其船隻購買適當及足夠保險，以保障他們船隻 / 財物安全。僱用服務者必須保證船隻在適航狀態及有效運作牌照，如果有任何一項缺失，船長 / 水手可以拒絕提供服務，而僱用服務者已支付費用將不會退還。",
    term3two:"",
    term3three:"",
    term3four:"",
    term4Title:"取消服務",
    term4:"天文台發出三號強風訊號或以上、紅雨或黑雨，當天教練服務 / 船長水手服務將會自動取消，而僱用教練服務者 / 僱用駕駛服務者可以安排退款或另訂服務日期。",
    term4two:"僱用駕駛服務者若在服務日期前三個工作天前取消服務，則本平台只會退回百分之五十 (50%) 作為取消服務退款，但若不足三個工作天，則不會作任何退款安排。",
    term4three:"船長 / 水手若因身體不適或其本身原因未能提供服務，本平台會盡一切能力安排另一個船長 / 水手替代其服務，若未能安排替代船長/水手補上，則僱用服務者已支付之金額將全數退還，而本平台絕不會作出任何賠償。",
    term4four:"",
    term4five:"",
    register:"以訪客身份結賬",
    accountUse:" 用戶帳戶是用來處理預訂記錄*",
    keepPwd:"記住賬號",
    forgot:"忘記密碼 ?",
    yourUsername:"你的用戶名...",
    yourPwd:"你的密碼...",
    yourPhone:"你的電話號碼",
    welcomeBack:"歡迎您回來船長哥哥!",
    welcomeJoin:"歡迎您加入船長哥哥！",
    pleasePay: "請把對應款項轉賬到",
    payMsg:"並傳送入數紙給指定Whatsapp",
    thankOrder:"謝謝, 你的訂單已經成功創建",
    backToHome:"返回首頁",
    myOrders:"我的訂單",
    perHours:"每小時",
    bookInfo:"預定資料",
    shipProvide:"每課最少2小時，時間雙方協議安排，最多兩個人同時上船學習駕駛",
    coaching:"客戶自行提供遊艇",
    companyAddress:"香港銅鑼灣軒尼詩道489號銅鑼灣廣場一期22樓2202室",
    mrLaw:"羅先生",
    bookingperiod:"預訂時數",
    atLeast:"請至少選擇一個船長或水手",
    chooseBookingDate:"請選擇預定時間",
    enterPhone:"請輸入聯絡電話",
    engNo:"發動機數量不得少於1",
    shipId:"請輸入船隻編號",
    upLoca:"請輸入上船地點",
    agreeA:"請同意條款",
    noLength:"請輸入船隻長度",
    twoHours: "每節不得少於2小時",
    today: "今天日期",
    reminder:"溫馨提示",
    reminderMsg:"請準時到達碼頭，當您看到我們的員工，請記得與他們確認訂單號",
    thankF:"感謝您的業務！如果需要更改預約時間或取消預約請聯絡我們的服務主任",
    attention: "提示!",
    sureCancel:"確定取消？",
    cancel:"取消",
    noPay:"未支付",
    paid: "已支付",
    canceled:"已取消",
    captain: "船長",
    sail: "水手",
    enterUserPwd:"請輸入賬號和密碼",

    email: "聯繫電郵",
    noEmail: "請輸入Email",
    emailRe:"郵箱格式有誤",
    no15:"不可超過15米",
    no15m: '船隻長度不可超過15米',
    overtime:"超時",
    paymentInfo:"支付方式 ",
    monthly: '月租船長/水手',
    monthlyMsg:"本公司承諾盡力尋找合適的月/長租船長/水手, 雙方議談落實最終月/長租定價.",
    name: "顧客名稱",
    iNeed: "我需要聘請",
    workArea:"上班地區",
    salary:"預計薪金",
    welfare:"福利及MPF",
    sailor:"水手",
    enterWorkArea: "請輸入工作地區",
    enterSalary:"請輸入預計薪金",
    enterWelfare:"請輸入福利及MPF",
    thankOrder2:"我們已收到你的訂單，稍後會有專人聯絡你",
    thankOrder3:"謝謝選用Captain Oppa",
}
