import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

import axios from '@/axios/axios'
import VueAxios from 'vue-axios'

window.baseURL = "http://captainoppa.com/api/v1"

axios.defaults.baseURL = window.baseURL

Vue.use(VueAxios, axios)


import Swal from 'sweetalert2'
Vue.prototype.$Swal = Swal

import 'bootstrap'

import router from './router/router'
import store from "@/vuex/store.js"

import 'view-design/dist/styles/iview.css';

import i18n from '@/locale'

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
}).$mount('#app')
