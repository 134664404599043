export default {
  home: 'Home',
  login: 'Login',
  components: 'Components',
  count_to_page: 'Count-to',
  tables_page: 'Table',
  split_pane_page: 'Split-pane',
  markdown_page: 'Markdown-editor',
  editor_page: 'Rich-Text-Editor',
  icons_page: 'Custom-icon',
  img_cropper_page: 'Image-editor',
  update: 'Update',
  doc: 'Document',
  join_page: 'QQ Group',
  update_table_page: 'Update .CSV',
  update_paste_page: 'Paste Table Data',
  multilevel: 'multilevel',
  directive_page: 'Directive',
  level_1: 'Level-1',
  level_2: 'Level-2',
  level_2_1: 'Level-2-1',
  level_2_3: 'Level-2-3',
  level_2_2: 'Level-2-2',
  level_2_2_1: 'Level-2-2-1',
  level_2_2_2: 'Level-2-2-2',
  excel: 'Excel',
  'upload-excel': 'Upload Excel',
  'export-excel': 'Export Excel',
  tools_methods_page: 'Tools Methods',
  drag_list_page: 'Drag-list',
  language:"Language",
  Qa:"Quick appointment",
  jianti: "Simple Chinese",
  fanti: "Traditional Chinese",
  English:"English",
  ss:"Services",
  howQ:"Reservation",
  quickBook:"Quick appointment with captain(s) or sailor(s)",
  contact:"Contact us",
  aboutUs:"About us",
  Charters:"Yacht Rental",
  hello:"Hello",
  regLog:"Register/Login",
  userPanel:"User panel",
  logout:"Log out",
  giveYouService:"Captain Oppa provides the services you need",
  active:"Coaching Service",
  longCaptain:"Long-period Captain hiring",
  recommend:"Recommended for you",
  noData:"No data",
  hire:"Book now",
  vDetail:"View Detail",
  sbp:"Simplified booking process",
  step:"Step",
  sss:"Select the required services",
  sort:"Sort",
  age:"Age",
  LH:"Low-High",
  HL:"High-Low",
  price:"Price",
  search:"Search",
  enterInfo:"Please enter the relevant information",
  lookFor:"Look for",
  license:"License",
  CanRent:"Available",
  workE:"Work experience",
  HireTheSailor:"Book now",
  bookingDate:"Booking Date",
  bookingTime:"Booking Time",
  engineNumber:"Number of Engine(s)",
  shippingName:"Yacht ID",
  shippingLength:"Yacht Length",
  shippingLocation:"Boarding Location",
  phone:"Contact Phone No.",
  remark:"Special request(s)",
  specialRequirment:"Special requirment..",
  orderDetail:"Order Details",
  needlunch:"Lunch is optional",
  SailingTime:"Sailing Time",
  iAgree:"I agree the Terms & Conditions set above",
  ordering:"loading...",
  placeAnOrder:"Place an order",
  orderNum:"Order Number",
  backIndex:"Return home page",
  myOrder:"My order",
  okOrder:"Thank you, your order has been created successfully",
  TheEngineNumber:"Number of Engine(s)",
  bookingAddress:"Boarding Location",
  bookingNum:"Order number",
  serviceNme:"Hiring Captain(s) / Sailor(s)",
  orderStatus:"Order status",
  action:"Operating…",
  loading:"Loading...",
  edit:"edit",
  cancelOrder:"Order cancelled ",
  confirm:"Confirm",
  unLock:"Unlock the best content of the Captain Oppa",
  saveStaff:"Save your favorite sailor or captain and check it out on the system",
  quickBookSentences:"Make a quick appointment with captain(s) or sailor(s)!",
  answerQ:"If you have any questions, leave it to the professional advisors of Captain Oppa to answer",
  becomeMember:"become a member now",
  senior:"Captain Oppa's recommended coaching",
  includeAll:"It includes short-term hiring captain(s) or sailor(s) / long-term hiring  captain(s) or sailor(s)",
  includeTrain:"It includes yacht pilot driving training",
  bookAndSelect:"Browse and select captain(s) / sailor(s) services",
  hasNews:"Here's all the information you need",
  SelectMatch:"Choose captain(s) /sailor(s) services according to individual needs",
  orderPay:"Place order and make payment",
  justSI:"Just give us simple information",
  youCan5:"You can complete this process in five minutes",
  haveAGoodTrip:"Arrive on time and enjoy our services. Have a nice trip !",
  chartShip:"Hassle free in chartering a ship",
  weijinInfo:"Founded in 1989, Vikings Charters Limited is one of the self-owned fleet companies in Hong Kong, mainly engaged in yacht rental, management and maintenance services",
  lookNow:"Yacht Rental",
  quictB:"Quick Booking service",
  quictBMsg:"A quick booking service can help you complete your booking faster. Just fill in the following and we will assign a dedicated service manager to match you with the most suitable captain(s) or sailor(s) !",
  bookNow:"Book now",
  captainNum:"No. of captain(s) required ",
  sailorNum:"No. of sailor(s) required",
  total:"Total",
  ifOverTime:'*If the actual time of departure exceeds the specified hours,',
  payMore:"Customers are requested to determine and pay the surcharge directly to the Captain / Sailors",
  thankFor:"Thank you for chosing our services of Captain Oppa. Contact us, please call 60994051",
  yourTime:"The time of service you requested",
  cardmsg:"After our Captain Oppa can provide the appropriate Captain, please deposit the deposit into our HSBC account CAPTAIN OPPA LIMITED - 143141562001  ,Then we will send the paper whatsapp to us 60994051",
  beforeMsg:"The day before the departure, the captain will contact you to make sure that the captain(s) / sailor(s) will be on board at the appointed dock on time",
  ifQueation:"If you have any questions or do not agree, please contact us Captain Oppa 60994051, thank you!",
  address:"Address",
  enterName:"Enter your name",
  enterEmail:"Enter your email",
  enterTitle:"Enter the title",
  enterInfo2:"Enter your content",
  sendMsg:"Send message",
  resultNum:"result",
  TeamMsg:"Our professional crew is consisted of  captains and seamen rich in seagoing experience. We are here to provide you with professional captain/seamen services, a broad range of water activities and yacht charters.The quality services we provide is competitively priced and will fit all your needs and budget.",
  TeamMsg2:"For all enquiries, feel free to contact us at 60994051.",
  pTeam:"Professional crew",
  termtitle:"Terms & Conditions",
  term1Title:"Good Communication",
  term1:"The service hirer and the captain / sailor should establish good communication to ensure the service provided shall meet the requirements.",
  term1two:"All captains provided by this platform are fully complied with Hong Kong Shipping Ordinances and their subsidiary legislation. However, individual captains will have different handling skills and qualifications for different types of vessels.", 
  term1three:"Service hirers and captains/seamen should communicate properly before starting work.",
  term1four:"This platform acts as an intermediary for providing captain/seamen services. Should there be any complaints of dissatisfactions, the captain/sailor and the service hirer should negotiate a solution among themselves.",
  term1five:"This platform will not have or accept any liability, obligation or responsibility whatsoever.The service hirer should understand vessels have different berthing/mooring locations. Captains/seamen will take time to travel to those locations. Both sides should try to reach an arrangement acceptable to all.",
  term2Title:"Safe operating",
  term2:"The service hirer shall comply with the recommendations or arrangements made by the captain/seaman to ensure the safety of the passengers and goods onboard.",
  term2two:"Hirer of instructional services shall comply with the directions given by the instructor.",
  term2three:"Any cost of damages to vessels or property provided by this platform as a result of non-compliance shall be payable in full (by the service hirer).",
  term2four:"Any vessel provided by the hirer of instructional services shall be properly insured and in a good state of seaworthiness, otherwise the instructor may refuse to provide the required service and any fees paid to this platform shall not be refunded.",
  term3Title:"Insurance and Permits",
  term3:"Service hirers must acquire the necessary insurance for the protection of their vessels /property.",
  term3two:"The service hirers must ensure the seaworthiness of their vessels and that they are properly licensed.",
  term3three:"Captains/seamen may refuse to provide services in case of any shortfall, and in such case any fees paid by the service hirer will not be refunded.",
  term4Title:"Cancellation",
  term4:"Should the Observatory issued a tropical cyclone signal No. 3 or above, or a red/black rainstorm warning, the service will be suspended for the day.  ",
  term4two:"The service hirer may reschedule the service or request for a refund. ",
  term4three:"Should the service hirer cancel any service three working days before the scheduled date, this platform will refund half of the fees payable. ",
  term4four:"In the event that less than three working days’ notice was given, no refund shall be made.  ",
  term4five:"Should the captain/seaman be ill or otherwise unable to provide the required service, this platform will try its best to provide a replacement. In case a replacement cannot be provided, any fees paid will be refunded. However, this platform shall not accept liability for any loss suffered by the service hirer.",
  register:"Check out as guest",
  accountUse:"User accounts are used to process reservation records*",
  keepPwd:"Remember account no.",
  forgot:"Forgot password?",
  yourUsername:"Your username...",
  yourPwd:"Your password...",
  yourPhone:"Your phone no",
  welcomeBack:"Welcome back to Captain-Oppa !",
  welcomeJoin:"Welcome to join Captain-Oppa!",
  pleasePay: "Please transfer the payment to",
  payMsg:"Please forward the transfer notice to our whatsapp",
  thankOrder:"Thank you, your order has been created successfully",
  backToHome:"Back homepage",
  myOrders:"My orders",
  perHours:"Per hour",
  bookInfo:"Booking information",
  shipProvide:"Cruiser is provided by customer",
  coaching:"Minimum two-hour coaching, actual duration will confirmed by both parties. Maximum two persons attend coaching section",
  companyAddress:"Unit 2202, 22/F, Causeway Bay Plaza I, 489 Hennessy Road",
  mrLaw:"Mr. Law",
  bookingperiod:"Booking period",
  atLeast:"Please select at least one captain or sailor",
    chooseBookingDate:"Please choose booking date",
    enterPhone:"Please enter your phone",
    engNo:"The number of engines must not be less than 1",
    shipId:"Please enter the vessel number",
    upLoca:"Please enter the embarkation point",
    agreeA:"Please agree to the terms",
    noLength:"Please enter length of vessel",
    twoHours: "Each session shall not be less than 2 hours",
    today: "Today",
    reminder:"Warm reminder",
    reminderMsg:"Please try to arrive at the dock on time. Once you see our staff, please remember to confirm the order number with them",
    thankF:"Thank you for your business!If you need to change the appointment time or cancel the reservation, please contact our service officer",
    attention: "Attention!",
    sureCancel:"Sure to cancel",
    cancel:"Cancel",
    noPay:"Pending",
    paid: "Paid",
    canceled:"Canceled",
    captain: "Captain",
    sail: "Sailor",
    enterUserPwd:"Please enter your username and password",
    email:"Email",
    overtime:"Over time",
    noEmail: "Please enter email",
    emailRe:"Incorrect email format",
    no15:"Not exceeding 15 meters",
    no15m: 'The length of the vessel shall not exceed 15 meters',
    paymentInfo:"Payment Method",
    monthly: 'Rental of Captain/Sailor',
    monthlyMsg:"We promise to try our best to find suitable captains/seamen working on monthly/long term basis. The final rate for the monthly/long term service is fixed by negotiation between the two sides.",
    name: "Name",
    iNeed: "I need to hire",
    workArea:"Work area",
    salary:"Expected salary",
    welfare:"Welfare and MPF",
    sailor:"Sailor",
    enterWorkArea: "Please enter work area",
    enterSalary:"Please enter estimated salary",
    enterWelfare:"Please enter benefits and MPF",
    thankOrder2:"We have recevied your order, our agent will contact you soon.",
    thankOrder3:"Thank you for choosing Captain Oppa"
}
